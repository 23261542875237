@font-face
{
    font-family: "Gilmer Regular";
    font-weight: normal;
    font-style: "Regular";
    src:local('Gilmer Regular') ,
    url('./Gilmer-Regular.otf') format('otf'),
    url('./Gilmer-Regular.woff2') format('woff2'),
    url('./Gilmer-Regular.woff') format('woff')
}
@font-face
{
    font-family: "Gilmer Light";
    font-weight: normal;
    font-style: "Light";
    src:local('Gilmer Light'),
    url('./Gilmer-Light.otf')  format('otf'),
    url('./Gilmer-Light.woff2') format('woff2'),
    url('./Gilmer-Light.woff') format('woff')
}
@font-face
{
    font-family: "Gilmer Medium";
    font-weight: normal;
    font-style: "Light";
    src:local('Gilmer Medium'),
    url('./Gilmer-Medium.otf')  format('otf'),
    url('./Gilmer-Medium.woff2') format('woff2'),
    url('./Gilmer-Medium.woff') format('woff')
}
@font-face
{
    font-family: "Gilmer Bold";
    font-weight: normal;
    font-style: "Bold";
    src:local('Gilmer Bold') ,
    url('./Gilmer-Bold.otf') format('otf'),
    url('./Gilmer-Bold.woff2') format('woff2'),
    url('./Gilmer-Bold.woff') format('woff')
}
@font-face
{
    font-family: "Gilmer Black";
    font-weight: normal;
    font-style: "Black";
    src:local('Gilmer Black')
}
@font-face
{
    font-family: "Gilmer Outline";
    font-weight: normal;
    font-style: "Outline";
    src:local('Gilmer Outline') ,
    url('./Gilmer-Outline.otf') format('otf'),
    url('./Gilmer-Outline.woff2') format('woff2'),
    url('./Gilmer-Outline.woff') format('woff')
}

/* Poppins Fonts */
@font-face
{
    font-family: "Poppins Bold";
    font-weight: '600';
    font-style: "normal";
    src:local('Poppins Bold') ,
    url('../fonts/Poppins/Poppins-Bold.ttf') format('ttf'),
}
@font-face
{
    font-family: "Poppins Regular";
    font-weight: '600';
    font-style: "normal";
    src:local('Poppins Regular') ,
    url('../fonts/Poppins/Poppins-Regular.ttf') format('ttf'),
}
@font-face
{
    font-family: "Poppins Semibold";
    font-weight: '600';
    font-style: "normal";
    src:local('Poppins Semibold') ,
    url('../fonts/Poppins/Poppins-SemiBold.ttf') format('ttf'),
}
@font-face
{
    font-family: "Poppins Light";
    font-weight: '600';
    font-style: "normal";
    src:local('Poppins Light') ,
    url('../fonts/Poppins/Poppins-Light.ttf') format('ttf'),
}

